const breakpoints = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
];

export const breakpointOrLarger = (targetBreakpoint) => (currentBreakpoint) => (
  breakpoints.indexOf(currentBreakpoint) >= breakpoints.indexOf(targetBreakpoint)
);

export const breakpointMdOrLarger = breakpointOrLarger('md');
