import { useEffect, useState } from 'react';

const defaultBreakpoints = {
  xs: {
    min: 0,
    max: 640,
  },
  sm: {
    min: 640,
    max: 768,
  },
  md: {
    min: 768,
    max: 1024,
  },
  lg: {
    min: 1024,
    max: 1200,
  },
  xl: {
    min: 1200,
    max: Infinity,
  },
};

const useBreakpoints = (breakpoints = defaultBreakpoints) => {
  const [breakpoint, setBreakpoint] = useState();

  const handleResize = () => {
    const windowWidth = window.innerWidth;

    const breakpointToUse = Object
      .entries(breakpoints)
      .find(
        ([, currentBreakpoint]) => (windowWidth >= currentBreakpoint.min && windowWidth <= currentBreakpoint.max),
      );

    setBreakpoint(Array.isArray(breakpointToUse) ? breakpointToUse[0] : '');
  };

  useEffect(() => handleResize(), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return breakpoint;
};

export default useBreakpoints;
